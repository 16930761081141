import React, {useEffect, useRef} from "react"
import Single from "../../single"
import info1 from "../../../../static/img/irstrat/emisoras.png";
import micro from "../../../../static/img/blog/micro-sitio-2.png";

const Detail = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })

    return (
        <Single location={props.location} title='Estructura del micro-sitio de RI'>
            <div className="main_blog_items">
                <div className="main_blog_item">
                    <div className="main_blog_image img_hover">
                        <img alt={" "} src={micro}/>
                    </div>
                    <div className="main_blog_text">
                        <div className="post_info">
                            <a href="#" className="date">
                                19 Nov. 2019
                            </a>
                            <div className="blog_author_area">
                 <span>
                  Por : J.A. Lovera
                </span>
                                <span>
                    Herramientas de RI
                </span>
                            </div>
                        </div>

                        <h2>Estructura y funcionalidad del micro-sitio de Relaciones con Inversionistas, ¿buena práctica
                            o
                            lineamiento?</h2>
                        <br/>
                        <h6>Es importante desmitificar el uso y estructuración del micro-sitio de Relaciones con
                            Inversionistas
                            ¿hay alguna guía para su funcionalidad y estructura?</h6>
                    </div>
                </div>
                <div className="s_main_text">
                    <p>
                        La respuesta es “sí”, existen un conjunto de lineamientos que los sitios de RI deben observar.
                        Aunque,
                        también, es importante mencionar que existen reglas no escritas en mejores prácticas: los sitios
                        de RI deben
                        ser de fácil accesibilidad, atractivos y contar con una arquitectura de software capaz de
                        ofrecer
                        herramientas interactivas y visualización en dispositivos móviles.
                    </p>
                    <p>La BMV ha tomado como modelos muchos lineamientos del NYSE y la SEC (*), entre ellos los
                        concernientes a
                        sitios de RI. De acuerdo con la SEC, conforme a lo establecido en la Regulación FD, 2008
                        (aplicable a
                        emisoras con programas de ADRs, y a emisoras con colocaciones privadas bajo Regla 144A y Reg.
                        S), se
                        reconoce al sitio de RI como un importante canal de distribución que para su uso apropiado debe
                        vigilar:</p>
                    <ul>
                        <li>i. La forma en que la información se carga; y,</li>
                        <li>ii. que la información sea cargada de manera puntual con amplia accesibilidad para los
                            inversionistas
                        </li>
                    </ul>
                    <p>De acuerdo con lo establecido por la SEC, al diseminar información en su sitio de RI, las
                        emisoras deberán
                        considerar:</p>
                    <img className="w-100 wow fadeIn" src={info1} alt="" data-wow-delay="0.2s" data-wow-duration="2.0s"/>
                    <p className={"mt-10"}>Desafortunadamente, en México existen aún muchos sitios de RI carentes de una
                        sección
                        RSS y de una función de alertas personalizada, es decir hay muchas emisoras que no cumplen si
                        quiera con el
                        estándar. Nuestras emisoras están aún lejanas de observar las mejores prácticas en sus sitios de
                        RI, aunque
                        es muy positivo observar el esfuerzo de algunas compañías pioneras en la materia que ya han
                        implementado la
                        visualización móvil de su contenido; no olvidemos que los usuarios de “smartphones” han
                        aumentado 72% a
                        nivel mundial en cuestión de un año, y que hoy en día existen más de 1.9K mills. de
                        usuarios de
                        “smartphones” en el mundo (**), y entre ellos la gran mayoría de los integrantes de su base de
                        inversionistas.</p>
                    <small>(*) Ver: <a target={'_blank'} href={'https://www.nyse.com'}>www.nyse.com</a>; <a
                        href={'https://www.sec.gov'} target={'_blank'}>www.sec.gov</a></small>
                    <br/>
                    <small>(**) Ver: <a href={'https://www.nielsen.com'} target={'_blank'}>www.nielsen.com</a></small>
                </div>
            </div>
        </Single>
    )
}

export default Detail
